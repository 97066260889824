* {
  box-sizing: border-box;
}

div.content {
  background-color: #022f5c;
  box-shadow: inset 0 0 5rem #00000080;
}

.grid-marketing {
  grid-column-gap: 10px;
  grid-row-gap: 50px;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 50px;
  display: grid;
}

.grid-title-1 {
  grid-area: 1 / 1 / 2 / 2;
  place-self: center;
}

.grid-text-1 {
  grid-area: 1 / 2 / 2 / 3;
}

.grid-img-1 {
  grid-area: 2 / 1 / 3 / 3;
}

.grid-title-2 {
  grid-area: 3 / 1 / 4 / 2;
  place-self: center;
}

.grid-text-2 {
  grid-area: 3 / 2 / 4 / 3;
}

.grid-img-2-1 {
  grid-area: 4 / 1 / 5 / 2;
  justify-self: center;
}

.grid-img-2-2 {
  grid-area: 4 / 2 / 5 / 3;
  justify-self: center;
}

@media (width <= 480px) {
  p {
    font-size: 1rem !important;
  }

  .grid-marketing {
    grid-row-gap: 30px;
    grid-template-rows: repeat(7, auto);
    grid-template-columns: 1fr;
    place-items: start center;
    margin-top: 30px;
  }

  .grid-title-1 {
    grid-area: 1 / 1 / 2 / 2;
  }

  .grid-text-1 {
    grid-area: 2 / 1 / 3 / 2;
  }

  .grid-img-1 {
    grid-area: 3 / 1 / 4 / 2;
  }

  .grid-title-2 {
    grid-area: 4 / 1 / 5 / 2;
  }

  .grid-text-2 {
    grid-area: 5 / 1 / 6 / 2;
  }

  .grid-img-2-1 {
    grid-area: 6 / 1 / 7 / 2;
  }

  .grid-img-2-2 {
    grid-area: 7 / 1 / 8 / 2;
  }
}
/*# sourceMappingURL=contact.fa372967.css.map */
