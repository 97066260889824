* {
    box-sizing: border-box;
}

div.content {
    background-color: #022F5C;
    box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
}

.grid-marketing {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 50px;
    margin-top: 50px;
}

.grid-title-1 { 
    grid-area: 1 / 1 / 2 / 2;
    align-self: center;
    justify-self: center;
}

.grid-text-1 {
    grid-area: 1 / 2 / 2 / 3;
}

.grid-img-1 {
    grid-area: 2 / 1 / 3 / 3;
}

.grid-title-2 {
    grid-area: 3 / 1 / 4 / 2;
    align-self: center;
    justify-self: center;
}

.grid-text-2 {
    grid-area: 3 / 2 / 4 / 3;
}

.grid-img-2-1 {
    grid-area: 4 / 1 / 5 / 2;
    justify-self: center;
}

.grid-img-2-2 {
    grid-area: 4 / 2 / 5 / 3;
    justify-self: center;
}

@media (max-width: 480px) {

    p {
        font-size: 1rem !important;
    }
    .grid-marketing {
        grid-template-rows: repeat(7, auto);
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
        justify-items: center;
        align-items: start;
        margin-top: 30px;
    }

    .grid-title-1 {
        grid-area: 1 / 1 / 2 / 2;
    }

    .grid-text-1 {
        grid-area: 2 / 1 / 3 / 2;
    }

    .grid-img-1 {
        grid-area: 3 / 1 / 4 / 2;
    }

    .grid-title-2 {
        grid-area: 4 / 1 / 5 / 2;
    }

    .grid-text-2 {
        grid-area: 5 / 1 / 6 / 2;
    }

    .grid-img-2-1 {
        grid-area: 6 / 1 / 7 / 2;
    }
    
    .grid-img-2-2 {
        grid-area: 7 / 1 / 8 / 2;
    }
}

